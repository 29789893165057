<template>
  <div class="max-width-4 mx-auto content-wrapper">
    <div class="clearfix mt4">
      <div class="md-col-8 col-10 mx-auto mt2">
        <h1 class="h1 center block orange">{{ $t('pageNotFound') }}</h1>

        <p class="text center">
          <router-link class="navbar-item none inline" title="Home- Silva Peak Residences" :to="{ name: 'Home'}">
            {{ $t('pageNotFoundLink') }}
          </router-link>
        </p>
      </div>
    </div>
  </div>
</template>

<script>

import i18n from "@/plugins/i18n";
import EventBus from "../assets/event-bus/event-bus";

export default {
  name: 'PageNotFound',
  data() {
    return {
      lang: 'de'
    };
  },
  mounted() {
    EventBus.$on('LANGUAGE_SWITCH', (payLoad) => {
      this.setLang(payLoad);
    });
  },
  methods: {
    currentLanguage() {
      return i18n.locale;
    },
    setLang(language) {
      this.lang = language;
    }
  },
  metaInfo: {
    title: '404',
    meta: [
      {
        content: 'Wir konnten diese Seite leider nicht finden.',
        name: 'description'
      },
      {
        property: 'og:description',
        content: 'Wir konnten diese Seite leider nicht finden.'
      }
    ]
  }
}
</script>

<style scoped>

.text {
  font-size: 1.2rem;
  line-height: 1.7rem;
}

a,
a:hover,
a:active,
a:focus {
  color: #e36e0e;
  box-sizing: border-box;
  text-decoration: none;
  border-bottom: 2px solid #e36e0e;
  word-break: break-all;
}

a:hover {
  border-color: #e36e0e;
}


.video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 0;
  height: 0;
  overflow: hidden;
}

.video-container iframe,
.video-container object,
.video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
