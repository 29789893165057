<template>
  <div class="max-width-4 mx-auto content-wrapper">
    <div class="clearfix mt4">
      <div class="md-col-8 col-10 mx-auto mt2">
        <h1 class="h1 center block orange">{{ $t('headline') }}</h1>

        <p class="text">
          <span class="orange">{{ $t('introText') }}</span>
        </p>
        <p class="text">
          <span class="bold">{{ $t('includedText') }}</span>
          {{ $t('introTextOne') }} <span class="orange">{{ $t('spa') }}</span>
          {{ $t('spaText') }}<br> <br>
          <span class="bold">{{ $t('spaNotice') }}</span>
        </p>

        <br>

        <h2 class="h2 center block orange">{{ $t('qualityHeadline') }}</h2>

        <p class="text">
          {{ $t('qualityText') }}</p>
        <p class="text">
          <span class="orange bold">{{ $t('qualityTextTeaser') }}</span>
          {{ $t('qualityTextTeaser2') }}<br> <br>
        </p>
      </div>
    </div>

    <div class="clearfix mt4">
      <div class="md-col-8 col-10 mx-auto">
        <h2 class="h2 center block orange">{{ $t('basicsHeadline') }}</h2>

        <p class="text">
          {{ $t('parking') }}<br><br>
          <strong>{{ $t('shoesRoomHeadline') }}</strong> {{ $t('shoesRoomText') }}
        </p>

        <p class="text">
          <span class="bold">{{ $t('checkinHeadline') }}</span> {{ $t('checkinText') }}
        </p>

        <p class="text">
          <span class="bold">{{ $t('paymentHeadline') }}</span> {{ $t('paymentText') }}
        </p>

        <p class="text">{{ $t('paymentNotice') }}</p>

        <p class="text">
          <span class="bold">{{ $t('advanceHeadline') }}</span> {{ $t('advanceText') }}
        </p>

        <p class="text">
          <span class="bold">{{ $t('shortStayHeadline') }}</span> {{ $t('shortStayText') }}
        </p>

        <br />

        <p class="block center">
          <a v-if="'de' === lang"  href="./Flyer_Silvretta_Card_NEU.pdf" class="h2 orange bold">{{ $t('silvrettaCardText') }}</a>
          <a v-if="'en' === lang"  href="./Flyer_Silvretta_Card_NEU_EN.pdf" class="h2 orange bold">{{ $t('silvrettaCardText') }}</a>
        </p>

        <br />

        <p class="text">
          {{ $t('taxText') }}
        </p>

        <div class="text">
          {{ $t('cancelText') }}
        </div>

        <div class="text">
          {{ $t('termsText') }}
        </div>

        <h2 class="h2 center block orange mt4">{{ $t('poiHeadline') }}</h2>
      </div>
    </div>

    <div class="clearfix mt2">
      <div class="col-10 mx-auto mt2 video-container">
        <iframe v-if="'de' === lang" width="560" height="315" src="https://www.youtube.com/embed/qEN45QZgK8c"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen></iframe>
        <iframe v-else width="560" height="315" src="https://www.youtube.com/embed/pnNpLBKUw3k" frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen></iframe>
      </div>
    </div>

    <div class="clearfix mt4">
      <div class="md-col-8 col-10 mx-auto mt2">
        <p class="h2 center block"><span class="orange">{{ $t('icTeaserHeadline') }}</span> - {{ $t('icTeaserText') }} - </p>
        <p  class="h2 center block"><a href="mailto:elisabeth@silvapeakresidences.com">elisabeth@silvapeakresidences.com</a></p>
      </div>
    </div>

    <div class="clearfix mt4">
      <div class="md-col-8 col-10 mx-auto mt2">
        <h2 class="h1 center block orange">{{ $t('activitiesHeadline') }}</h2>

        <div class="clearfix">
          <div class="col col-6 p1 video-container small">
            <iframe width="560" height="200" src="https://www.youtube.com/embed/8JqIox2poi4"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen></iframe>
          </div>

          <div class="col col-6 p1 video-container small">
            <iframe width="560" height="200" src="https://www.youtube.com/embed/MDC8w3cfLl0"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen></iframe>
          </div>
        </div>

        <h3 class="h2 center block orange">{{ $t('furtherInfos') }}</h3>

        <div class="clearfix">
          <div class="col col-6 p1 video-container small">
            <iframe width="560" height="200" src="https://www.youtube.com/embed/uLmvLdzKwfE"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen></iframe>
          </div>

          <div class="col col-6 p1 video-container small">
            <iframe width="560" height="200" src="https://www.youtube.com/embed/gvMfpnnuOyc"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen></iframe>
          </div>
        </div>

        <p class="text">
          {{ $t('closingText') }}<br>
          {{ $t('soonText') }}<br>
        </p>

        <h2 class="h2 orange bold center mt4">
          Love Elisabeth<br>
          Big Hugs
        </h2>
      </div>
    </div>
  </div>
</template>

<script>

import i18n from "@/plugins/i18n";
import EventBus from "../assets/event-bus/event-bus";

export default {
  name: 'Home',
  data() {
    return {
      lang: 'de',
      publicPath: process.env.BASE_URL,
    };
  },
  mounted() {
    EventBus.$on('LANGUAGE_SWITCH', (payLoad) => {
      this.setLang(payLoad);
    });
  },
  methods: {
    currentLanguage() {
      return i18n.locale;
    },
    setLang(language) {
      this.lang = language;
    }
  },
  metaInfo: {
    title: 'Deine einzigartige Experience',
    meta: [
      {
        name: 'description',
        content: 'Schön, dass Du da bist – buch jetzt Deinen Traum Urlaub im Schnee! Hausherrin Elisabeth hat immer ein offenes Ohr (und die Hand am Telefon oder die Finger auf den Tasten ...)'
      },
      {
        property: 'og:description',
        content: 'Schön, dass Du da bist – buch jetzt Deinen Traum Urlaub im Schnee! Hausherrin Elisabeth hat immer ein offenes Ohr (und die Hand am Telefon oder die Finger auf den Tasten ...)'
      }
    ]
  }
}
</script>

<style scoped>

.text {
  font-size: 1.2rem;
  line-height: 1.7rem;
}

a,
a:hover,
a:active,
a:focus {
  color: #e36e0e;
  box-sizing: border-box;
  text-decoration: none;
  border-bottom: 2px solid #e36e0e;
  word-break: break-all;
}

a:hover {
  border-color: #e36e0e;
}

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 0;
  height: 0;
  overflow: hidden;
}

.video-container.small {
  padding-bottom: 25.25%;
}

.video-container iframe,
.video-container object,
.video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
