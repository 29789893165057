import Vue from 'vue';
import App from './App.vue';
import i18n from '@/plugins/i18n';
import Header from './components/Header.vue';
import Footer from './components/Footer.vue';
import router from './router';
import VueMeta from 'vue-meta';

Vue.config.productionTip = false;

Vue.component('app-header', Header);
Vue.component('app-footer', Footer);

Vue.use(VueMeta, {
    // optional pluginOptions
    refreshOnceOnNavigation: true,
    attribute: 'data-vue-meta',
    ssrAttribute: 'data-vue-meta-server-rendered',
    tagIDKeyName: 'vmid',
});

new Vue({
    i18n,
    router,
    render: h => h(App),
}).$mount('#app');
