<template>
    <div>
        <header class="clearfix header max-width-4 mx-auto">
            <div class="left">
                <router-link :to="{ name: 'Home'}" title="Silva Peak Residencs- Experiences" target="_self"
                             class="text-decoration-none logo">
                    <img src="../assets/silvapeak-orange.png" alt="Silva Peak Residencs- Experiences">
                    <span class="hide">Silva Peak Residences</span>
                </router-link>
            </div>
            <div class="right">
                <button type="button" :disabled="isCurrentLanguage(entry)" class="flags" v-for="entry in allLanguages"
                        :key="entry.title" @click="changeLocale(entry.language)">
                    {{ entry.title }}
                </button>
            </div>
        </header>

        <div class="clearfix mb2 heading-wrapper">
            <div class="clearfix headline">
                <h1 class="h1 headline">
                    {{ $t('teaser') }}
                </h1>
            </div>

            <div class="badge" style="">
                <span class="h1 block bold center">Quality Time 100%</span>
            </div>
        </div>
    </div>
</template>

<script>
    import i18n from "@/plugins/i18n";
    import EventBus from "../assets/event-bus/event-bus";

    export default {
        name: 'Header',
        data() {
            return {
                languages: [
                    {flag: 'at', language: 'de', title: 'Deutsch', active: true},
                    {flag: 'gb', language: 'en', title: 'Englisch', active: true}
                ]
            };
        },
        computed: {
            // a computed getter
            allLanguages: function () {
                // `this` points to the vm instance
                return this.languages.filter((lang) => {
                    return lang.active && i18n.locale !== lang.language
                });
            },
        },
        methods: {
            changeLocale(locale) {
                i18n.locale = locale;
                EventBus.$emit('LANGUAGE_SWITCH', locale);
            },
            isCurrentLanguage(locale) {
                return i18n.locale === locale.language;
            }
        }
    }
</script>

<style scoped>
    .header {
        position: relative;
        height: 4rem;
        z-index: 100;
        background: white;
    }

    .left {
        text-align: left;
        position: absolute;
        top: 0.2rem;
        left: 0.5rem;
    }

    .right {
        text-align: right;
        position: absolute;
        top: 0.6rem;
        right: 0.5rem;
    }

    .heading-wrapper {
        height: 65vh;
        background-color: lightgrey;
        background-image: url('../assets/img/A37T7091.jpg');
        background-position: center;
        background-size: cover;
        position: relative;
    }

    .badge {
        display: flex;
        right: 10%;
        top: 10%;
        position: absolute;
        background: #e36e0e;
        border-radius: 50%;
        width: 150px;
        height: 150px;
        padding: 10px;
        align-items: center;
        justify-content: center;
    }

    .badge span {
        font-size: 40px;
        line-height: 38px;
        word-break: break-word;
    }

    .headline {
        font-family: 'Bebas Neue', cursive;
        color: white;
        left: 0;
        bottom: 0;
        margin: 1rem;
        z-index: 10;
        position: absolute;
        font-size: 4rem;
    }

    @media screen and (max-width: 480px) {
        .headline {
            font-size: 2rem;
            line-height: 2.4rem;
            margin: 0.5rem;
            width: 80%;
        }
    }
</style>
