<template>
  <footer class="clearfix mt4 footer">
    <div class="col col-12 footer-image">
      <div class="clearfix">
        <div class="max-width-4 mx-auto">
          <div class="clearfix mt4 ">
            <div class="md-col-8 sm-col-12 mx-auto center">
              <ul class="list-style-none list-reset">
                <li class="h5 p0 mt2">
                  Made with <span class="red">&#9829;</span> in Galtuer/ Tyrol, {{ year }} <br>
                </li>
                <li class="h5 p0 mt2">
                  <router-link class="navbar-item none inline" title="Imprint- Silva Peak Residences" :to="{ name: 'Imprint'}">
                    Imprint
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
  data: function () {
    return {
      year: new Date().getFullYear(),
    };
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.footer {
  font-family: 'Open Sans', sans-serif;
  text-align: center;
  margin: 0 auto 40px;

  font-size: 1.2rem;
  line-height: 1.4rem;
}

.footer .red {
  color: #e25555;
}

.link {
  color: inherit;
  text-decoration: none;
  padding-bottom: 0.1rem;
  border-bottom: 2px solid black;
}

.link:hover {
  color: inherit;
  text-decoration: underline;

}

a,
a:hover,
a:active,
a:focus {
  color: #e36e0e;
  box-sizing: border-box;
  text-decoration: none;
  border-bottom: 2px solid #e36e0e;
  word-break: break-all;
}

a:hover {
  border-color: #e36e0e;
}
</style>
