<template>
  <div class="max-width-4 mx-auto content-wrapper">
    <div class="clearfix mt4">
      <div class="md-col-8 col-10 mx-auto mt2">
        <h1 class="h1 center block orange">{{ $t('termsHeadline') }}</h1>

        <p class="text center">
          <span class="bold">Silva Peak Residences</span><br>
          Elisabeth und Klaus Türtscher <br>
          6563 Galtür, Austria <br>
          +43 664 2003707 <br>
          <a href="mailto:elisabeth@silvapeakresidences.com" title="elisabeth@silvapeakresidences.com">elisabeth@silvapeakresidences.com</a>
        </p>

        <h3 class="h2 orange center">{{ $t('termsOneH') }}</h3>
        <p class="text">
          {{ $t('termsOneText') }}
        </p>

        <h3 class="h2 orange center">{{ $t('termsTwoH') }}</h3>
        <p class="text">
          {{ $t('termsTwoText') }}
        </p>

        <h2 class="h2 orange center">{{ $t('termsThreeH') }}</h2>
        <p class="text">
          {{ $t('termsThreeText') }}
        </p>

        <h3 class="pt2 h2 orange center">{{ $t('termsFourH') }}</h3>

        <p class="text">
          {{ $t('termsFourText') }}
        </p>

        <p class="text">
          {{ $t('termsFourText2') }}
        </p>

        <p class="text">
          {{ $t('termsFourText3') }}
        </p>

        <h4 class="pt2 h2 orange center">{{ $t('termsFourH2') }}</h4>

        <p class="text">
          {{ $t('termsFourText4') }}
        </p>

        <h3 class="pt2 h2 orange center">{{ $t('termsFiveH') }}</h3>

        <p class="text">
          {{ $t('termsFiveText') }}
        </p>

        <h3 class="pt2 h2 orange center">{{ $t('termsSixH') }}</h3>

        <p class="text">
          {{ $t('termsSixText') }}
        </p>

      </div>
    </div>
  </div>
</template>

<script>

import i18n from "@/plugins/i18n";
import EventBus from "../assets/event-bus/event-bus";

export default {
  name: 'Terms',
  data() {
    return {
      lang: 'de'
    };
  },
  mounted() {
    EventBus.$on('LANGUAGE_SWITCH', (payLoad) => {
      this.setLang(payLoad);
    });
  },
  methods: {
    currentLanguage() {
      return i18n.locale;
    },
    setLang(language) {
      this.lang = language;
    }
  },
  metaInfo: {
    title: 'Impressum',
    meta: [
      {
        name: 'description',
        content: 'Hi, i’m Ben, an experience designer currently working for moodley.'
      },
      {
        property: 'og:title',
        content: 'Impressum - Silva Peak Residences',
        // following template options are identical
        // template: '%s - My page',
        template: chunk => `${chunk}`,
        vmid: 'og:title'
      },
      {
        property: 'og:description',
        content: 'Hi, i’m Ben, an experience designer currently working for moodley.'
      }
    ]
  }
}
</script>

<style scoped>

.text {
  font-size: 1.2rem;
  line-height: 1.7rem;
}

a,
a:hover,
a:active,
a:focus {
  color: #e36e0e;
  box-sizing: border-box;
  text-decoration: none;
  border-bottom: 2px solid #e36e0e;
  word-break: break-all;
}

a:hover {
  border-color: #e36e0e;
}


.video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 0;
  height: 0;
  overflow: hidden;
}

.video-container iframe,
.video-container object,
.video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
