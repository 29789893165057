import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const messages = {
    'de': {
        teaser: 'Deine einzigarte SilvaPeak Erfahrung!',

        headline: 'Schön, dass Du da bist – buch jetzt Deinen Traum Urlaub im Schnee!',
        introText: 'Hausherrin Elisabeth hat immer ein offenes Ohr (und die Hand am Telefon oder die Finger auf den Tasten ...)',
        includedText: 'Im Preis inbegriffen:',
        introTextOne: 'Bettwäsche und Handtücher, High-Speed-Internet-Code finden Sie bei der Sprechanlage, Smart-TV,',
        spa: 'Spa vor Ort:',
        spaText: 'Zirbensauna, Dampfbad, Luis-Trenker-Infrarotkabine – Saunatücher liegen bereit, Bademantel und Slipper im Apartment',
        spaNotice: 'täglich von 17.00 Uhr bis 20.00 Uhr zur Zeit nur mit Voranmeldung',

        basicsHeadline: 'Weitere Basics für deine einzigartige Experience',
        parking: 'Tesla Chargepoint & allgemeine Ladestation für Elektroautos, Tiefgarage und freie Parkplätze',
        shoesRoomHeadline: 'Schuhraum:',
        shoesRoomText: 'befindet sich im Kellergeschoss und ist über die Stiege direkt im Hoteleingang zu erreichen – bitte das Bike in der Tiefgarage abstellen. Vielen herzlichen Dank!',
        checkinHeadline: 'CheckIn / CheckOut:',
        checkinText: ' Die Apartments sind bei Ankunft ab ca. 16.00 Uhr verfügbar und sollten bei Abreise ab 10.00 Uhr geräumt werden. Bei verspäteter Ankunft nach 18:00 Uhr benachrichtigen Sie uns bitte im Voraus.',
        paymentHeadline: 'Bezahlung:',
        paymentText: 'BAR, Master, VISA - wir bitten Sie die Rechnung am Vorabend Ihrer Abreise zu begleichen – vielen Dank!',
        paymentNotice: 'Bankautomat befindet sich direkt am Dorfplatz – Raiffeisen Bank Paznaun',
        advanceHeadline: 'Anzahlung:',
        advanceText: 'Informationen zur Anzahlung finden Sie im Angebot ( incl. 25% Verwaltungsgebühr ).',
        shortStayHeadline: 'Kurzaufenthalt:',
        shortStayText: 'Wir bitten Sie um Ihr Verständnis, dass für Kurzaufenthalte von weniger als 4 Tagen ein Aufpreis berechnet wird, den Sie auch im Angebot ausführlich sehen können.',
        taxText: 'Alle Preise verstehen sich pro Apartment und Tag zuzüglich lokaler Steuern in Höhe von 2,70 € pro Person und Tag / Kurtaxe (Winter / Sommer) und Endreinigung gemäß Ihrem Angebot.',
        cancelText: 'Stornierungen nur schriftlich',
        termsText: 'Allgemeine Geschäftsbedingungen für die Hotellerie',
        poiHeadline: 'Mittendrin, statt nur dabei: Alles in Gehdistanz – vom Supermarkt bis zum Super-Skigebiet, von der Kirche bis zum Kaffeehaus und vom Bäcker bis zum Berg ... Ja sogar Ischgl ist nur 10 (Auto-)Minuten von hier entfernt.',

        icHeadline: 'Weltneuheit – Silva Peak Residences ist das 1. Instant Change Hotel – WOW phänomenal!',
        icNew: 'NEU',
        icNewText: '– Instant Change Methode hilft Dir dabei, all Deine Blockaden, Ängste, Zweifel - deinen mentalen und emotionalen Zustand blitzschnell zu verändern und dein komplettes Leben in Lichtgeschwindigkeit positiv auszurichten.',
        icIntroText: 'Instant Change ist intensiver, leichter und schneller als oft einseitige Coaching-Ansätze, weil die Veränderung auf mehreren Ebenen gleichzeitig geschieht: auf der geistigen, der physischen und der energetischen Ebene.',
        icCallText: 'Lade Dich gerne zu einem Informationsgespräch ein - +43 664 200 3707 – Love Elisabeth',
        icSeminarHeadline: 'Einladung zum kostenlosen',
        icSeminarHeadlineLink: 'Seminar',
        icSessionHeadline: 'Nutze Deine Chance -',
        icSessionHeadlineLink: 'buch jetzt Deine Session',
        icSessionHeadlineTwo: '– ist per Zoom möglich',
        icBookHeadline: 'Mit großer Freude schenken wir Dir das',
        icBookHeadlineLink: 'Instant Change Buch',
        icBookHeadlineTwo: '- viel Spaß!',

        icTeaserHeadline: 'Weltneuheit - WIEHW',
        icTeaserText: 'ist eine neue bahnbrechende Methode welche schnell, nachhaltig und einfach Deine Sorgen und Angst in Körper, Geist und Seele löst. Gerne lade ich Dich zu einem Info Gespräch ein – bitte um Termin Vereinbarung',
        icTeaserContact: 'elisabeth@silvapeakresidences.com',

        closingText: 'Freuen uns riesig aufs Wiedersehen und auf den gigantischen Winter mit Dir.',
        soonText: 'Auf bald!',

        activitiesHeadline: 'Deine Aktivitäten rund ums Silva Peak',
        furtherInfos: 'Weitere Impressionen von Galtür!',

        termsHeadline: 'Impressum',
        termsOneH: 'Haftungshinweis',
        termsOneText: 'Silva Peak Residences übernimmt keinerlei Haftung für die Richtigkeit, Verlässlichkeit und Vollständigkeit oder Gesetzeskonformität externer Links. Für den Inhalt der verlinkten Seiten sind ausschließlich deren Betreiber verantwortlich.',

        termsTwoH: 'Bilder',
        termsTwoText: 'Silva Peak Residences, Tourismusverband Paznaun-Ischgl',

        termsThreeH: 'Datenschutzerklärung',
        termsThreeText: 'Wir legen großen Wert auf den Schutz Ihrer Daten. Um Sie in vollem Umfang über die Verwendung personenbezogener Daten zu informieren, bitten wir Sie die folgenden Datenschutzhinweise zur Kenntnis zu nehmen.',

        termsFourH: 'Wichtiger Hinweis',
        termsFourText: 'Wir haben im Sinne der besseren Lesbarkeit der Inhalte dieser Webseite oder in unseren verschiedenen Sozialen Netzwerken in denen wir aktiv sind, entweder die männliche oder weibliche Form von personenbezogenen Bezeichnungen gewählt. Dies impliziert keinesfalls eine Benachteiligung des jeweils anderen Geschlechts. Wenn wir also zum Beispiel von Besucher oder Mitarbeitern sprechen, meinen wir gleichermaßen und selbstverständlich auch Besucherinnen und Mitarbeiterinnen. Alle Informationen sprechen Frauen und Männer gleichermaßen an.',
        termsFourText2: 'Diese Webseite ist leider für Personen mit körperlichen Einschränkungen wie zum Beispiel Sehen, Hören oder Motorik nicht vollständig funktionsfähig. Wir entschuldigen diesen Umstand und sind gerne bereit hier über andere Kanäle unsere Angebote zu erläutern. Gerne verweisen wir an dieser Stelle auf die Schlichtungsstelle für Behindertengleichstellung des Sozialministeriums der Republik Österreich.',
        termsFourText3: 'Angebotsabweichungen auf Grund Programmänderungen vorbehalten. Fehler und Irrtümer trotz sorgfältiger Prüfung vorbehalten.',
        termsFourH2: 'Inhalt des Onlineangebotes',
        termsFourText4: 'Die Silva Peak Residences übernimmt keinerlei Gewähr für die Aktualität, Korrektheit, Vollständigkeit oder Qualität der bereitgestellten Informationen. Haftungsansprüche gegen die Silva Peak Residences, welche sich auf Schäden materieller oder ideeller Art beziehen, die durch die Nutzung oder Nichtnutzung der dargebotenen Informationen bzw. durch die Nutzung fehlerhafter und unvollständiger Informationen verursacht wurden, sind grundsätzlich ausgeschlossen, sofern seitens der Silva Peak Residences kein nachweislich vorsätzliches oder grob fahrlässiges Verschulden vorliegt. Alle Angebote sind freibleibend und unverbindlich. Die Silva Peak Residences behält es sich ausdrücklich vor, Teile der Seiten oder das gesamte Angebot ohne gesonderte Ankündigung zu verändern, zu ergänzen, zu löschen oder die Veröffentlichung zeitweise oder endgültig einzustellen.',

        termsFiveH: 'Urheber- und Kennzeichenrecht',
        termsFiveText: 'Die Silva Peak Residences ist bestrebt, in allen Publikationen die Urheberrechte der verwendeten Grafiken, Tondokumente, Videosequenzen und Texte zu beachten, von ihr selbst erstellte Grafiken, Tondokumente, Videosequenzen und Texte zu nutzen oder auf lizenzfreie Grafiken, Tondokumente, Videosequenzen und Texte zurückzugreifen. Alle innerhalb des Internetangebotes genannten und ggf. durch Dritte geschützten Marken- und Warenzeichen unterliegen uneingeschränkt den Bestimmungen des jeweils gültigen Kennzeichenrechts und den Besitzrechten der jeweiligen eingetragenen Eigentümer. Allein aufgrund der bloßen Nennung ist nicht der Schluss zu ziehen, dass Markenzeichen nicht durch Rechte Dritter geschützt sind! Das Copyright für veröffentlichte, von der Silva Peak Residences selbst erstellte Objekte bleibt allein beim Besitzer der Seiten. Eine Vervielfältigung oder Verwendung solcher Grafiken, Tondokumente, Videosequenzen und Texte in anderen elektronischen oder gedruckten Publikationen ist ohne ausdrückliche Zustimmung der Silva Peak Residences nicht gestattet.',

        termsSixH: 'Copyright/Haftung',
        termsSixText: 'Im Hinblick auf die technischen Eigenschaften des Internet kann keine Gewähr für die Authentizität, Richtigkeit und Vollständigkeit der im Internet zur Verfügung gestellten Informationen übernommen werden. Es wird auch keine Gewähr für die Verfügbarkeit oder den Betrieb der gegenständlichen Homepage und ihrer Inhalte übernommen Jede Haftung für unmittelbare, mittelbare oder sonstige Schäden, unabhängig von deren Ursachen, die aus der Benutzung oder Nichtverfügbarkeit der Daten und Informationen dieser Homepage erwachsen, wird, soweit rechtlich zulässig, ausgeschlossen. Der Inhalt dieser Homepage ist urheberrechtlich geschützt. Die Informationen sind nur für die persönliche Verwendung bestimmt. Jede weitergehende Nutzung insbesondere die Speicherung in Datenbanken, Vervielfältigung und jede Form von gewerblicher Nutzung sowie die Weitergabe an Dritte auch in Teilen oder in überarbeiteter Form ohne Zustimmung der jeweiligen Organisation ist untersagt. Jede Einbindung einzelner Seiten unseres Angebotes in fremde Frames ist zu unterlassen.',

        termsSevenH: 'Sonstiges',
        termsSevenText: 'Sofern Teile oder einzelne Formulierungen dieses Textes der geltenden Rechtslage nicht, nicht mehr oder nicht vollständig entsprechen sollten, bleiben die übrigen Teile des Dokumentes in ihrem Inhalt und ihrer Gültigkeit davon unberührt.',

        termsEightH: 'Cookies',
        termsEightText: 'Cookies sind kleine Dateien, die es dieser Webseite ermöglichen auf dem Computer des Besuchers spezifische, auf den Nutzer bezogene Informationen zu speichern, während unsere Website besucht wird. Cookies helfen uns dabei, die Nutzungshäufigkeit und die Anzahl der Nutzer unserer Internetseiten zu ermitteln, sowie unsere Angebote für Sie komfortabel und effizient zu gestalten. Wir verwenden einerseits Session-Cookies, die ausschließlich für die Dauer Ihrer Nutzung unserer Website zwischengespeichert werden und zum anderen permanente Cookies, um Informationen über Besucher festzuhalten, die wiederholt auf unsere Website zugreifen. Der Zweck des Einsatzes dieser Cookies besteht darin, eine optimale Benutzerführung anbieten zu können sowie Besucher wiederzuerkennen und bei wiederholter Nutzung eine möglichst attraktive Website und interessante Inhalte präsentieren zu können. Der Inhalt eines permanenten Cookies beschränkt sich auf eine Identifikationsnummer. Name, IP-Adresse usw. werden nicht gespeichert. Eine Einzelprofilbildung über Ihr Nutzungsverhalten findet nicht statt. Eine Nutzung unserer Angebote ist auch ohne Cookies möglich. Sie können in Ihrem Browser das Speichern von Cookies deaktivieren, auf bestimmte Webseiten beschränken oder Ihren Webbrowser (Chrome, IE, Firefox,…) so einstellen, dass er sie benachrichtigt, sobald ein Cookie gesendet wird. Sie können Cookies auch jederzeit von der Festplatte ihres PC löschen. Bitte beachten Sie aber, dass Sie in diesem Fall mit einer eingeschränkten Darstellung der Seite und mit einer eingeschränkten Benutzerführung rechnen müssen.',

        pageNotFound: '404 - Seite kann nicht gefunden werden!',
        pageNotFoundLink: 'Zurück zur Startseite!',

        qualityHeadline: 'QualityTime 100% ist das Zauberwort Sommer 2021 im Silva Peak Residences - buche ganz gelassen Deinen Sommer Urlaub.',
        qualityText: 'Sommer 2021 – Vorfreude auf blühende Wiesen, zwitschernde Vögel, intakte Natur, den größten Fitnessparcour vor der Haustür, lachende und spielende Kinder IN und MIT der Natur, lässt jedes Elternherz höher schlagen.',

        qualityTextTeaser: 'QualityTime 100% heißt',
        qualityTextTeaser2: '– in den Silva Peak Residences – kostenlose Stornierung oder Umbuchung bis 7 Tage vor Anreise. Sie können die Buchung ohne Angabe von Gründen stornieren. Diese Aktion ist gültig für Buchungen, deren Anreisedatum zwischen 1. Mai 2021 und 31. Oktober 2021 liegt.',

        silvrettaCardText: 'Silvretta Card Sommer 2021'
    },
    'en': {
        teaser: 'Your unique SilvaPeak experience!',

        headline: 'Glad you are here - book your dream vacation in the snow now!',
        introText: 'CEO Elisabeth always has an open ear (and the hand on the phone or the fingers on the keys ...)',
        includedText: 'Included in the price:',
        introTextOne: 'Bed linen and towels, high-speed internet code can be found at the intercom, smart TV,',
        spa: 'Spa on site:',
        spaText: 'Swiss stone pine sauna, steam bath, Luis Trenker infrared cabin - sauna towels are provided, bathrobe and slippers in the apartment',
        spaNotice: 'daily from 17.00 o\'clock to 20.00 o\'clock at present only with reservation',

        basicsHeadline: 'More basics for your unique experience',
        parking: 'Tesla Chargepoint & general charging station for electric cars, underground parking and free parking spaces.',
        shoesRoomHeadline: 'Shoe room:',
        shoesRoomText: 'is located in the basement and can be reached via the stairs directly in the hotel entrance - please park the bike in the underground garage. Thank you very much!',
        checkinHeadline: 'CheckIn / CheckOut:',
        checkinText: 'The apartments are available from about 16:00 on arrival and should be vacated from 10:00 on departure. In case of late arrival after 18:00 please notify us in advance.',
        paymentHeadline: 'Payment:',
        paymentText: 'CASH, Master, VISA - we kindly ask you to pay the bill on the eve of your departure - thank you very much.!',
        paymentNotice: 'ATM is located directly at the village square - Raiffeisen Bank Paznaun',
        advanceHeadline: 'Down payment:',
        advanceText: 'Information about the deposit can be found in the offer ( incl. 25% administration fee )..',
        shortStayHeadline: 'Short stay:',
        shortStayText: 'We ask for your understanding that for short stays of less than 4 days a surcharge is calculated, which you can also see in detail in the offer.',
        taxText: 'All prices are per apartment per day plus local taxes of 2,70 € per person per day / tourist tax (winter / summer) and final cleaning according to your offer..',
        cancelText: 'Cancellations only in writing',
        termsText: 'General terms and conditions for the hotel industry',
        poiHeadline: 'In the middle of it all, instead of just being there: Everything within walking distance - from the supermarket to the super ski area, from the church to the coffee house and from the bakery to the mountain ... Yes even Ischgl is only 10 (car) minutes away from here.',

        icHeadline: 'World first - Silva Peak Residences is the 1st Instant Change Hotel - WOW phenomenal!',
        icNew: 'NEW',
        icNewText: '– Instant Change Method helps you to change all your blocks, fears, doubts - your mental and emotional state in a flash and to align your entire life positively in the speed of light..',
        icIntroText: 'Instant Change is more intense, easier, and faster than often one-sided coaching approaches because change happens on multiple levels at once: the mental, physical, and energetic levels.',
        icCallText: 'Invite you to an informational interview - +43 664 200 3707 - Love Elisabeth',
        icSeminarHeadline: 'Invitation to the free',
        icSeminarHeadlineLink: 'seminar',
        icSessionHeadline: 'Take your chance -',
        icSessionHeadlineLink: 'book your session now',
        icSessionHeadlineTwo: '– is possible by zoom',
        icBookHeadline: 'With great pleasure we give you the',
        icBookHeadlineLink: 'Instant Change book',
        icBookHeadlineTwo: '- have fun!',

        closingText: 'We are looking forward to seeing you again and to the gigantic winter with you..',
        soonText: 'See you soon!',

        activitiesHeadline: 'Your activities around Silva Peak',
        furtherInfos: 'More impressions of Galtür!',

        termsHeadline: 'Impressum',
        termsOneH: 'Liability notice',
        termsOneText: 'Silva Peak Residences accepts no liability for the accuracy, reliability and completeness or legal compliance of external links. The operators of the linked pages are solely responsible for their content.',

        termsTwoH: 'Pictures',
        termsTwoText: 'Silva Peak Residences, Tourismusverband Paznaun-Ischgl',

        termsThreeH: 'Privacy policy',
        termsThreeText: 'We attach great importance to the protection of your data. In order to fully inform you about the use of personal data, we ask you to take note of the following data protection information.',

        termsFourH: 'Important note',
        termsFourText: 'We have chosen to use either the masculine or feminine form of personal terms for the sake of better readability of the content of this website or in our various social networks in which we are active. This in no way implies any discrimination against the other gender. So, for example, when we talk about visitors or staff members, we also mean female visitors and staff members equally and as a matter of course. All information addresses women and men equally. Translated with www.DeepL.com/Translator (free version)',
        termsFourText2: 'Unfortunately, this website is not fully functional for people with physical disabilities such as vision, hearing or motor skills. We apologise for this circumstance and are happy to explain our offers here via other channels. We would like to refer you to the Arbitration Board for Equal Opportunities for Persons with Disabilities of the Ministry of Social Affairs of the Republic of Austria.',
        termsFourText3: 'Offers are subject to change due to programme changes. Errors and omissions excepted despite careful checking.',
        termsFourH2: 'Content of the online offer',
        termsFourText4: 'Silva Peak Residences accepts no responsibility for the topicality, correctness, completeness or quality of the information provided. Liability claims against Silva Peak Residences relating to material or immaterial damage caused by the use or non-use of the information provided or by the use of incorrect or incomplete information are excluded, unless Silva Peak Residences can be proven to have acted with intent or gross negligence. All offers are subject to change and non-binding. Parts of the pages or the complete publication including all offers and information might be extended, changed or partly or completely deleted by Silva Peak Residences without separate announcement.',

        termsFiveH: 'Copyright and trademark law',
        termsFiveText: 'Silva Peak Residences endeavours to observe the copyrights of the graphics, sound documents, video sequences and texts used in all publications, to use graphics, sound documents, video sequences and texts it has created itself or to make use of licence-free graphics, sound documents, video sequences and texts. All brand names and trademarks mentioned on the website and possibly protected by third parties are subject without restriction to the provisions of the applicable trademark law and the ownership rights of the respective registered owners. The mere mention of a trademark does not imply that it is not protected by the rights of third parties! The copyright for published objects created by Silva Peak Residences itself remains solely with the owner of the pages. Any duplication or use of objects such as diagrams, sounds or texts in other electronic or printed publications is not permitted without Silva Peak Residences\' agreement.',

        termsSixH: 'Copyright/Liability',
        termsSixText: 'In view of the technical characteristics of the Internet, no guarantee can be given for the authenticity, correctness and completeness of the information made available on the Internet. Nor is any guarantee given for the availability or operation of the present homepage and its contents. Any liability for direct, indirect or other damage, irrespective of its causes, arising from the use or non-availability of the data and information on this homepage is excluded to the extent permitted by law. The content of this homepage is protected by copyright. The information is intended for personal use only. Any further use, in particular storage in databases, reproduction and any form of commercial use as well as disclosure to third parties, even in part or in revised form, without the consent of the respective organisation is prohibited. Any integration of individual pages of our offer into external frames is to be refrained from.',

        termsSevenH: 'Other',
        termsSevenText: 'If sections or individual terms of this statement are not legal or correct, the content or validity of the other parts remain uninfluenced by this fact.',

        termsEightH: 'Cookies',
        termsEightText: 'Cookies are small files that enable this website to store specific, user-related information on the visitor\'s computer while our website is being visited. Cookies help us to determine the frequency of use and the number of users of our website, as well as to design our offers for you comfortably and efficiently. We use session cookies, which are only temporarily stored for the duration of your use of our website, and permanent cookies to record information about visitors who repeatedly access our website. The purpose of using these cookies is to be able to offer an optimal user experience as well as to recognise visitors and to be able to present a website that is as attractive as possible and interesting content in the event of repeated use. The content of a permanent cookie is limited to an identification number. Name, IP address etc. are not stored. Individual profiling of your usage behaviour does not take place. It is also possible to use our offers without cookies. You can deactivate the storage of cookies in your browser, limit it to certain websites or set your web browser (Chrome, IE, Firefox,...) so that it notifies you as soon as a cookie is sent. You can also delete cookies from your PC\'s hard drive at any time. Please note, however, that in this case you will have to expect a restricted display of the page and restricted user guidance.',

        pageNotFound: '404 - Page cannot be found!',
        pageNotFoundLink: 'Back to the homepage!',

        icTeaserHeadline: 'World novelty - WIEHW',
        icTeaserText: 'is a new groundbreaking method that quickly, sustainably and easily solves your worries and fears in body, mind and soul. I would be happy to invite you to an info session - please make an appointment.',
        icTeaserContact: 'elisabeth@silvapeakresidences.com',

        qualityHeadline: 'QualityTime 100% is the magic word for summer 2021 at Silva Peak Residences - book your summer holiday with peace of mind.',
        qualityText: 'Summer 2021 - anticipation of flowering meadows, chirping birds, intact nature, the largest fitness course on the doorstep, laughing and playing children IN and WITH nature, makes every parent\'s heart beat faster.',

        qualityTextTeaser: 'QualityTime 100% means',
        qualityTextTeaser2: '- at Silva Peak Residences - free cancellation or rebooking up to 7 days before arrival. You can cancel the booking without giving any reason. This promotion is valid for bookings with arrival dates between 1 May 2021 and 31 October 2021.',

        silvrettaCardText: 'Silvretta Card summer 2021'
    }
};

const i18n = new VueI18n({
    locale: 'de', // set locale
    fallbackLocale: 'en', // set fallback locale
    messages, // set locale messages
});

export default i18n;
