import Vue from 'vue';
import Router from 'vue-router';
import Home from '../pages/Home';
import Terms from '../pages/Terms';
import PageNotFound from '../pages/404';

Vue.use(Router);

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home
    },
    {
      path: '/imprint',
      name: 'Imprint',
      component: Terms
    },
    {
      path: '/:pathMatch(.*)*',
      component: PageNotFound
    },
  ],
  // eslint-disable-next-line no-unused-vars
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
});
